<template>
    <div class="home-container">
      <div class="header">
        <user-info
          class="header-item user-info"
          :user-id="userId"
          :user-name="userName"
          :user-avatar="userAvatar"
          @log-out="handleLogOut"
        ></user-info>
        <language-icon class="header-item language"></language-icon>
        <switch-theme class="header-item theme"></switch-theme>
      </div>
      <stream-preview ref="streamPreviewRef" style="display: none;"></stream-preview>
      <room-control
        ref="roomControl"
        :given-room-id="givenRoomId"
        @create-room="handleCreateRoom"
        @enter-room="handleEnterRoom"
      ></room-control>
    </div>
</template>
<script>
  import UserInfo from '@/TUIRoom/components/RoomHeader/UserInfo.vue';
  import LanguageIcon from '@/TUIRoom/components/RoomHeader/Language.vue';
  import SwitchTheme from '@/TUIRoom/components/RoomHeader/SwitchTheme.vue';
  import StreamPreview from '@/TUIRoom/components/RoomHome/StreamPreview.vue';
  import RoomControl from '@/TUIRoom/components/RoomHome/RoomControl.vue';
  import { getBasicInfo } from '@/config/basic-info-config';
  import TUIRoomEngine from '@tencentcloud/tuiroom-engine-js';
  import useGetRoomEngine from '@/TUIRoom/hooks/useRoomEngine';
  import { list, add } from '@/api/index';
  const roomEngine = useGetRoomEngine();
  export default {
    name: 'Home',
    components: { UserInfo, LanguageIcon, StreamPreview, SwitchTheme, RoomControl },
    data() {
      return {
        givenRoomId: '',
        basicInfo: null,
        userName: '',
        userAvatar: '',
        userId: '',
        windowHeight: window.innerHeight,
        dataList: [],
        page: 1,
        pageSize: 100,
      };
    },
    watch: {
      windowHeight: function () {
        // this.initSize();
      },
    },
    async mounted() {
    //   this.initSize();
      window.onresize = () => {
        // this.initSize();
      };
      sessionStorage.removeItem('tuiRoom-roomInfo');
      this.givenRoomId = this.$route.query.roomId || '';
      if (sessionStorage.getItem('tuiRoom-userInfo')) {
        this.basicInfo = JSON.parse(sessionStorage.getItem('tuiRoom-userInfo'));
      } else {
        this.basicInfo = await getBasicInfo();
        this.basicInfo && sessionStorage.setItem('tuiRoom-userInfo', JSON.stringify(this.basicInfo));
      }
      this.userName = this.basicInfo.userName;
      this.userAvatar = this.basicInfo.avatarUrl;
      this.userId = this.basicInfo.userId;
      const { sdkAppId, userId, userSig } = this.basicInfo;
      // 登录 TUIRoomEngine
      await TUIRoomEngine.login({ sdkAppId, userId, userSig });
      this.$refs.streamPreviewRef.startStreamPreview();
    },
    methods: {
      initSize() {
        const f = document.getElementById("room-container");
        const t = document.getElementById("stream-container");
        const tv = document.getElementById("stream-container-div");
        const o = document.documentElement.clientWidth / 1440;
        const a = document.documentElement.clientHeight / 860;
        const m = o < a ? o : a;
        const c = document.documentElement.clientWidth - f.clientWidth * m;
        if (c < 430) {
          if (this.$route.query.roomId) {
            tv.style.display = 'none';
            f.style.marginLeft = "0";
          } else {
            f.style.visibility = "hidden";
            f.style.display = 'none';
            t.style.width = `${document.documentElement.clientWidth}px`;
            return;
          }
        } else {
          if (this.$route.query.roomId) {
            tv.style.display = 'block';
            f.style.marginLeft = "40px";
          } else {
            f.style.display = 'block';
            f.style.visibility = "visible";
            t.style.width = '740px';
          }
        }
      },
      setTUIRoomData(action, mode) {
        const roomParam = this.$refs.streamPreviewRef.getRoomParam();
        const roomData = {
          action,
          roomMode: mode || 'FreeToSpeak',
          roomParam,
        };
        sessionStorage.setItem('tuiRoom-roomInfo', JSON.stringify(roomData));
      },
      async checkRoomExistWhenCreateRoom(roomId) {
        let isRoomExist = false;
        const tim = roomEngine.instance?.getTIM();
        try {
          await tim.searchGroupByID(roomId);
          isRoomExist = true;
        } catch (error) {
          // 房间不存在
        }
        return isRoomExist;
      },
      // 创建房间时生成房间号
      async generateRoomId() {
        const roomId = Math.ceil(Math.random() * 1000000);
        const isRoomExist = await this.checkRoomExistWhenCreateRoom(String(roomId));
        if (isRoomExist) {
          return await this.generateRoomId();
        }
        return roomId;
      },
      // 处理点击【创建房间】
      async handleCreateRoom(obj) {
        if (!this.basicInfo.isAllow) {
          this.$refs.roomControl.showMsg('No permission');
          return;
        }
        this.setTUIRoomData('createRoom', obj.mode);
        const roomId = await this.generateRoomId();
        const that = this;
        add({
          roomid: roomId,
          uid: this.userId,
          username: this.userName,
          dateRange: '11-22',
          subject: obj.subject,
        }).then((response) => {
          console.debug(response);
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          that.$router.push({ path: 'room', query: { roomId } });
        });
      },
      // 处理点击【进入房间】
      async handleEnterRoom(roomId) {
        this.setTUIRoomData('enterRoom');
        this.$router.push({
          path: 'room',
          query: {
            roomId,
          },
        });
      },
      // 处理用户点击页面左上角【退出登录】
      handleLogOut() {
        sessionStorage.removeItem('tuiRoom-loginToken');
        sessionStorage.removeItem('tuiRoom-loginUserInfo');
        this.$router.push({
          path: 'login',
        });
      },
      // getlist
      getList() {
        this.dataList = [
        {
          id: 5,
          created_at: "2023-05-23 13:20:25",
          updated_at: "2023-05-23 13:20:25",
          content: null,
          uid: 1,
          username: "test123",
          dateRange: "11-12",
          subject: "teststewtset",
          url: "http://wwwbaidu.com",
          subStatus: "进行中",
          overTime: null,
          type: "云会议",
          roomid: "123123",
        },
        {
          id: 6,
          created_at: "2023-05-23 13:20:25",
          updated_at: "2023-05-23 13:20:25",
          content: null,
          uid: 1,
          username: "test123",
          dateRange: "11-12",
          subject: "hhssdfsd",
          url: "http://wwwbaidu.com",
          subStatus: "已结束",
          overTime: null,
          type: "云会议",
          roomid: "1231236",
        },
        ];
        return;
        const that = this;
        list({
          page: this.page,
          pageSize: this.pageSize,
        }).then((response) => {
          if (response.data.code != 0) {
            console.log(response.data.msg);
            return;
          }
          that.dataList = response.data.data.list;
          this.page += 1;
        }).catch(() => {});
      },
    },
  };
</script>
<style>
  @import '../TUIRoom/assets/style/black-theme.scss';
  @import '../TUIRoom/assets/style/white-theme.scss';
</style>
<style lang="scss" scoped>
  .home-container {
    width: 100%;
    height: 100%;
    background: var(--background-color-style);
    color: #B3B8C8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: PingFangSC-Medium;
    transition: background .5s,color .5s;
    :not([class|="el"]) {
      transition: background-color .5s,color .5s;
    }
    .header {
      width: 100%;
      position: absolute;
      top: 0;
      padding: 22px 24px;
      display: flex;
      align-items: center;
      .header-item {
        &:not(:first-child) {
          margin-left: 16px;
        }
        .language{
          cursor: pointer;
        }
        .theme{
          cursor: pointer;
        }
      }
    }
  }
</style>
