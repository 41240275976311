import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home.vue';
import Login from '../views/login.vue';
import Create from '../views/create.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
  },
  {
    path: '/create',
    name: 'create',
    component: Create,
  },
  {
    path: '/room',
    name: 'room',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/room.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next)=> {
  if (to.path === '/login') {
    next();
  }
  const hasToken = sessionStorage.getItem('tuiRoom-loginToken');
  if (!hasToken && to.path !== '/login') {
      return next(`/login`);
  } else {
    next();
  }
});
export default router;
