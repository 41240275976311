<template>
  <div class="home-container">
    <div class="header">
      <user-info
        class="header-item user-info"
        :user-id="userId"
        :user-name="userName"
        :user-avatar="userAvatar"
        @log-out="handleLogOut"
      ></user-info>
      <language-icon class="header-item language"></language-icon>
      <switch-theme class="header-item theme"></switch-theme>
    </div>
    <stream-preview ref="streamPreviewRef" style="display: none;"></stream-preview>
    <room-content
      ref="roomContent"
      :given-list="dataList"
      @create-room="handleCreateRoom"
      @enter-room="handleEnterRoom"
    ></room-content>
  </div>
</template>

<script>
import UserInfo from '@/TUIRoom/components/RoomHeader/UserInfo.vue';
import LanguageIcon from '@/TUIRoom/components/RoomHeader/Language.vue';
import SwitchTheme from '@/TUIRoom/components/RoomHeader/SwitchTheme.vue';
import StreamPreview from '@/TUIRoom/components/RoomHome/StreamPreview.vue';
import RoomContent from '@/TUIRoom/components/RoomHome/RoomContent.vue';
import { getBasicInfo } from '@/config/basic-info-config';
import TUIRoomEngine from '@tencentcloud/tuiroom-engine-js';
import { list } from '@/api/index';
export default {
  name: 'Home',
  components: { UserInfo, LanguageIcon, StreamPreview, SwitchTheme, RoomContent },
  data() {
    return {
      givenRoomId: '',
      basicInfo: null,
      userName: '',
      userAvatar: '',
      userId: '',
      windowHeight: window.innerHeight,
      dataList: [],
      page: 1,
      pageSize: 100,
    };
  },
  watch: {
    windowHeight: function () {
      // this.initSize();
    },
  },
  async mounted() {
    // this.initSize();
    window.onresize = () => {
      // this.initSize();
    };
    this.getList();
    sessionStorage.removeItem('tuiRoom-roomInfo');
    this.givenRoomId = this.$route.query.roomId || '';

    if (sessionStorage.getItem('tuiRoom-userInfo')) {
      this.basicInfo = JSON.parse(sessionStorage.getItem('tuiRoom-userInfo'));
    } else {
      this.basicInfo = await getBasicInfo();
      this.basicInfo && sessionStorage.setItem('tuiRoom-userInfo', JSON.stringify(this.basicInfo));
    }
    this.userName = this.basicInfo.userName;
    this.userAvatar = this.basicInfo.avatarUrl;
    this.userId = this.basicInfo.userId;
    const { sdkAppId, userId, userSig } = this.basicInfo;
    // 登录 TUIRoomEngine
    await TUIRoomEngine.login({ sdkAppId, userId, userSig });
    this.$refs.streamPreviewRef.startStreamPreview();
  },
  methods: {
    initSize() {
      const f = document.getElementById("room-container");
      const t = document.getElementById("stream-container");
      const tv = document.getElementById("stream-container-div");
      const o = document.documentElement.clientWidth / 1440;
      const a = document.documentElement.clientHeight / 860;
      const m = o < a ? o : a;
      const c = document.documentElement.clientWidth - f.clientWidth * m;
      if (c < 430) {
        if (this.$route.query.roomId) {
          tv.style.display = 'none';
          f.style.marginLeft = "0";
        } else {
          f.style.visibility = "hidden";
          f.style.display = 'none';
          t.style.width = `${document.documentElement.clientWidth}px`;
          return;
        }
      } else {
        if (this.$route.query.roomId) {
          tv.style.display = 'block';
          f.style.marginLeft = "40px";
        } else {
          f.style.display = 'block';
          f.style.visibility = "visible";
          t.style.width = '740px';
        }
      }
    },
    setTUIRoomData(action, mode) {
      const roomParam = this.$refs.streamPreviewRef.getRoomParam();
      const roomData = {
        action,
        roomMode: mode || 'FreeToSpeak',
        roomParam,
      };
      sessionStorage.setItem('tuiRoom-roomInfo', JSON.stringify(roomData));
    },
    // 处理点击【创建房间】
    handleCreateRoom() {
      this.$router.push({ path: 'create' });
    },
    // 处理用户点击页面左上角【退出登录】
    handleLogOut() {
      sessionStorage.removeItem('tuiRoom-loginToken');
      sessionStorage.removeItem('tuiRoom-loginUserInfo');
      this.$router.push({
        path: 'login',
      });
    },
    // 处理点击【进入房间】
    async handleEnterRoom(roomId) {
      this.setTUIRoomData('enterRoom');
      this.$router.push({
        path: 'room',
        query: {
          roomId,
        },
      });
    },
    // getlist
    getList() {
      // this.dataList = [
      // {
      //   id: 5,
      //   created_at: "2023-05-23 13:20:25",
      //   updated_at: "2023-05-23 13:20:25",
      //   content: null,
      //   uid: 1,
      //   username: "test123",
      //   dateRange: "11-12",
      //   subject: "西红柿项目开发会议碰头是",
      //   url: "http://wwwbaidu.com",
      //   subStatus: "进行中",
      //   overTime: null,
      //   type: "云会议",
      //   roomid: "123123",
      //   avatar: "http://juyouqu.oss-cn-chengdu.aliyuncs.com/default/20230531/d43195d9cdcc645601280006d2191f61.jpeg?x-oss-process=style/pic",
      // },
      // ];
      // return;
      const that = this;
      list({
        page: this.page,
        pageSize: this.pageSize,
      }).then((response) => {
        if (response.data.code != 0) {
          console.log(response.data.msg);
          return;
        }
        that.dataList = response.data.data.list;
        this.page += 1;
      }).catch(() => {});
    },
  },
};
</script>

<style>
@import '../TUIRoom/assets/style/black-theme.scss';
@import '../TUIRoom/assets/style/white-theme.scss';

</style>

<style lang="scss" scoped>
.home-container {
  width: 100%;
  height: 100%;
  background: var(--background-color-style);
  color: #B3B8C8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PingFangSC-Medium;
  transition: background .5s,color .5s;
  :not([class|="el"]) {
    transition: background-color .5s,color .5s;
  }
  .header {
    width: 100%;
    position: absolute;
    top: 0;
    padding: 22px 24px;
    display: flex;
    align-items: center;
    .header-item {
      &:not(:first-child) {
        margin-left: 16px;
      }
      .language{
        cursor: pointer;
      }
      .theme{
        cursor: pointer;
      }
    }
  }
}
</style>
