export default {
  'Sign in': '请登录',
  'Phone Login': '手机登录',
  'Email Login': '邮箱登录',
  'I have read and agree to the': '我已阅读并同意',
  'Privacy Policy': '隐私协议',
  and: '和',
  'Terms of Use': '用户协议',
  login: '登录',
  register: '注册',
  'Mobile number': '请输入您的手机号',
  'Account number': '请输入您的账号',
  'Account password': '请输入您的密码',
  'Account password again': '请再次输入您的密码',
  'Verification code': '请输入验证码',
  'Email address': '请输入邮箱',
  SEND: '获取验证码',
  ' ': '倒计时',
  'The room does not exist, please confirm the room number or create a room!': '房间不存在，请确认房间号或创建房间！',
  'Log out': '退出登录',
  'Edit profile': '编辑资料',
  'User Name': '用户名',
  'Please input user name': '请输入用户名',
  'Username length should be greater than 0': '昵称长度应该大于0',
  Save: '保存',
  Camera: '摄像头',
  Mic: '麦克风',
  'Off Camera': '摄像头已关闭',
  'Mic settings': '麦克风设置',
  Output: '输出',
  Speaker: '扬声器',
  Test: '测试',
  Stop: '停止测试',
  Preview: '视频画面',
  Mirror: '翻转镜像',
  Resolution: '分辨率',
  'More Camera Settings': '更多摄像头设置',
  'Join the room ?': '是否进入房间?',
  'You are invited to room ': '您被邀请进入 ',
  Room: '房间',
  Join: '确定进入',
  'New Room': '新建房间',
  'Free Speech Room': '自由发言房间',
  'Raise Hand Room': '举手发言房间',
  'Join Room': '加入',
  'Enter room ID': '输入房间号',
  'Low Definition': '流畅',
  'Standard Definition': '标清',
  'High Definition': '高清',
  'Super Definition': '超清',
  'End sharing': '结束共享',
  'Stop sharing?': '是否停止屏幕共享？',
  'Stop sharing': '停止共享',
  Sharing: '屏幕共享中',
  'Share screen': '共享屏幕',
  'Others will no longer see your screen after you stop sharing. Are you sure you want to stop?': '是否结束当前的共享屏幕，停止后所有人将无法继续观看屏幕内容',
  'Others will no longer see your screen after you stop sharing.': '停止后所有人将无法继续观看屏幕内容',
  'You currently do not have sharing permission, please raise your hand to apply for sharing permission first': '您当前没有共享权限，请先举手申请上台获取共享权限',
  Host: '主持人',
  Me: '我',
  me: '我',
  More: '更多',
  'Raise hand': '举手',
  Agree: '同意',
  Chat: '聊天',
  Cancel: '取消',
  Exit: '退出全屏',
  'Full screen': '全屏',
  Members: '管理成员',
  Invite: '邀请成员',
  Settings: '设置',
  End: '结束会议',
  'You are currently the room host, please select the appropriate action.If you select "Leave Room", the room will not be dissolved and you will need to appoint a new host.': '您当前是房间主持人，请选择相应操作。若选择“离开房间”，则房间不会解散，您需要指定新主持人。',
  'Are you sure you want to leave this room?': '确定离开房间吗？',
  'New host': '选择主持人',
  'Transfer and leave': '移交并离开',
  'Leave room?': '是否要离开房间',
  'Select a new host': '请选择新的房间主持人',
  'The host closed the room.': '主持人结束会议，已解散房间',
  Note: '通知',
  Confirm: '确认',
  Leave: '离开房间',
  Dismiss: '解散房间',
  'Moderator changed to ': '主持人已变更为',
  'Muted by the moderator': '已被主持人禁言',
  'Type a message': '说点什么',
  Send: '发送',
  'Failed to send the message': '发送消息失败',
  'Applying for the stage': '正在申请上台',
  Check: '查看',
  'Disable all audios': '禁言所有人',
  'Disable all videos': '禁画所有人',
  'Member List': '成员列表',
  Unmute: '解除禁言',
  'Cancel Unmute': '取消解除禁言',
  Mute: '禁言',
  members: '人',
  'Failed to get chat message': '获取聊天消息失败',
  'Agree to the stage': '同意上台',
  'Cancel stage': '取消邀请上台',
  'Invite stage': '邀请上台',
  'Refuse stage': '拒绝上台',
  'Enable video': '解除禁画',
  'Cancel Enable video': '取消解除禁画',
  'Disable video': '禁画',
  'Enable chat': '允许文字聊天',
  'Disable chat': '禁止文字聊天',
  'Kick out': '踢出房间',
  'is sharing their screen': '的屏幕分享',
  'Apply to stage application': '请求上台申请',
  Reject: '拒绝',
  'Reject All': '全部拒绝',
  'Please raise your hand to apply': '如果您想发言请先举手申请上麦',
  'The host invites you to speak on stage': '主持人邀请您上台发言',
  'After agreeing to go on stage, you can turn on the camera and microphone. Do you agree to go on stage?': '同意上台后可打开摄像头和麦克风，是否同意上台？',
  'Hand down': '手放下',
  'Step down(Master)': '邀请下台',
  'Step down': '下台',
  'The host has approved your application': '主持人同意了你的上台申请',
  'The host has rejected your application for the stage': '主持人拒绝了你的上台申请',
  'You have been invited by the host to step down, please raise your hand if you need to speak': '您已被主持人邀请下台，需要发言请先举手',
  'Select a screen/window': '选择屏幕/窗口',
  Screen: '屏幕',
  Window: '窗口',
  Share: '开始分享',
  'Select a screen or window first': '请选择要分享的屏幕或者窗口',
  Grid: '一屏九等分',
  'Gallery on right': '右侧成员列表',
  'Gallery at top': '顶部成员列表',
  'Network ': '网络',
  'Latency: ': '网络延迟：',
  'Frame rate: ': '帧率：',
  'Bitrate: ': '码率：',
  Unknown: '状态未知',
  Excellent: '状态极佳',
  Good: '状态较好',
  Fair: '状态一般',
  Poor: '状态差',
  'Very poor': '状态极差',
  Disconnected: '断开连接',
  'Share the room ID or invite link': '您可以通过复制房间号或者邀请链接的方式邀请更多人加入房间',
  'Invite by room number': '通过房间号邀请',
  'Invite via room link': '通过房间链接邀请',
  'Invite via client scheme': '通过客户端 scheme 邀请',
  'Contact us': '联系我们',
  'Member management': '成员管理',
  'Join our QQ group chat or email us.': '如果有任何问题欢迎加入我们的QQ群或者发送邮件',
  'group chat': '群',
  Email: '邮箱地址',
  'Audio settings': '声音设置',
  'Camera settings': '摄像头设置',
  'Copied successfully': '复制成功',
  'accepted the invitation to the stage': '接受了上台邀请',
  'declined the invitation to the stage': '拒绝了上台邀请',
  'The host has muted all': '主持人已开启全体静音',
  'The host has unmuted all': '主持人已解除全体静音',
  'The host has turned off your microphone': '主持人已关闭您的麦克风',
  'The host invites you to turn on the microphone': '主持人邀请你打开麦克风',
  'Turn on the microphone': '打开麦克风',
  'The host has turned on the ban on all paintings': '主持人已开启全体禁画',
  'The host has lifted the ban on all paintings': '主持人已解除全体禁画',
  'The host has turned on the ban on all chat': '主持人已开启全体禁止文字聊天',
  'The host has lifted the ban on all chat': '主持人已解除全体禁止文字聊天',
  'The host has turned off your camera': '主持人已关闭您的摄像头',
  'The host invites you to turn on the camera': '主持人邀请你打开摄像头',
  'The host has turned off your screen sharing': '主持人已关闭您的屏幕分享',
  'Turn on the camera': '打开摄像头',
  'Keep it closed': '保持关闭',
  'You have been banned from text chat by the host': '您被主持人禁止文字聊天',
  'You are allowed to text chat by the host': '您被主持人允许文字聊天',
  'kicked out of the room by the host': '被主持人踢出房间',
  'Reject on Stage failed, please retry': '拒绝 {userName} 上台失败, 请重试',
  'Has been fully muted and cannot open the microphone': '已被全员静音，无法打开麦克风',
  'Has been muted by the host and cannot open the microphone': '已被主持人静音，无法打开麦克风',
  'To apply to speak in the room, please raise your hand first to apply for the microphone': '申请发言房间，请先举手申请上麦',
  'Has been full static painting, can not open the video': '已被全员静画，无法打开视频',
  'It has been stilled by the host and cannot open the video': '已被主持人静画，无法打开视频',
  'Failed to enter the room.': '进入房间失败！',
  'Has been full static painting, can not share your screen': '已被全员静画，无法打开屏幕分享',
  'Load More': '加载更多',
  'Microphone not detected on current device': '当前设备未检测到麦克风',
  'Camera not detected on current device': '当前设备未检测到摄像头',
  'Camera And Microphone not detected on current device': '当前设备未检测到摄像头和麦克风',
  'Please enter the correct phone number': '请输入正确的手机号',
  'Register success': '注册成功',
  'No permission': '没有权限',
  'Please enter the correct account': '请输入您的账号',
  'Please enter the correct password': '请输入您的密码',
  'Error Account Or Password': '账号或密码错误',
  'Please confirm the password': '请确认您的密码是否一致',
  'Register error': '注册失败',
  'Please login': '请登录',
  'Account login': '账号登录',
  'Account register': '立即注册',
  'Login success': '登录成功',
  'Meeting is over': '会议已结束',
  'roomId is no exist': '会议已结束',
  'create room': '创建房间',
  'Please input subject': '请输入主题',
};
