import axios from '@/libs/api.request';

export const login = (params) => {
    params = params || '';
    return axios.request({
        url: '/api/login',
        method: 'post',
        params: params,
    });
};

export const register = (params) => {
    params = params || '';
    return axios.request({
        url: '/api/register',
        method: 'post',
        params: params,
    });
};

export const list = (params) => {
    params = params || '';
    return axios.request({
        url: '/api/api/meeting/list',
        method: 'post',
        params: params,
    });
};

export const add = (params) => {
    params = params || '';
    return axios.request({
        url: '/api/api/meeting/add',
        method: 'post',
        params: params,
    });
};

export const join = (params) => {
    params = params || '';
    return axios.request({
        url: '/api/api/meeting/join',
        method: 'post',
        params: params,
    });
};

export const over = (params) => {
    params = params || '';
    return axios.request({
        url: '/api/api/meeting/edit',
        method: 'post',
        params: params,
    });
};
