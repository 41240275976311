<template>
  <div class="home-container">
    <div class="header">
      <login-header class="header-item user-info"></login-header>
      <language-icon class="header-item language"></language-icon>
      <switch-theme class="header-item theme" style="display:none;"></switch-theme>
    </div>
    <div id="login-img" class="login-img" style="width: 888px;height: 818px;transform-origin: left center;">
      <img style="width: 100%;height: 100%;" src="../TUIRoom/assets/imgs/login-back.png">
    </div>
    <login-control
      ref="loginControl"
      @update-phone="updatePhone"
      @update-verify="updateVerify"
      @send-verify-code="sendVerifyCode"
      @submit-form="submitForm"
      @login-success="loginSuccess"
      @register-success="registerSuccess"
    ></login-control>
  </div>
</template>

<script>
import LoginHeader from '@/TUIRoom/components/RoomLogin/LoginHeader.vue';
import LanguageIcon from '@/TUIRoom/components/RoomHeader/Language.vue';
import SwitchTheme from '@/TUIRoom/components/RoomHeader/SwitchTheme.vue';
import LoginControl from '@/TUIRoom/components/RoomLogin/LoginControl.vue';


export default {
  name: 'Login',
  components: { LanguageIcon, LoginControl, SwitchTheme, LoginHeader },
  data() {
    return {
      windowHeight: window.innerHeight,
      phone: '',
      code: '',
    };
  },
  async mounted() {
    sessionStorage.removeItem('tuiRoom-roomInfo');
    sessionStorage.removeItem('tuiRoom-userInfo');
    this.initSize();
    window.onresize = () => {
      this.initSize();
    };
  },
  watch: {
    windowHeight: function () {
      this.initSize();
    },
  },
  methods: {
    initSize() {
      const f = document.getElementById("login-img");
      const t = document.getElementById("login-container");
      const o = document.documentElement.clientWidth / 1440;
      const a = document.documentElement.clientHeight / 860;
      const m = o < a ? o : a;
      const c = document.documentElement.clientWidth - f.clientWidth * m;
      if (c < 430) {
          f.style.visibility = "hidden";
          f.style.display = 'none';
          t.style.marginLeft = "0px";
          return;
      }
      f.style.display = 'block';
      f.style.visibility = "visible";
      f.style.transform = `scale(${m})`;
      t.style.marginLeft = "40px";
    },
    // 处理用户点击页面左上角【退出登录】
    handleLogOut() {
      // 接入方处理 logout 方法
    },
    updatePhone(str) {
      this.phone = str;
    },
    updateVerify(str) {
      this.code = str;
    },
    sendVerifyCode() {},
    submitForm() {
      console.log(this.phone);
      console.log(this.code);
    },
    loginSuccess() {
      this.$router.push({ path: 'home' });
    },
    registerSuccess() {
      this.$refs.loginControl.doLogin();
    },
  },
};
</script>

<style>
@import '../TUIRoom/assets/style/black-theme.scss';
@import '../TUIRoom/assets/style/white-theme.scss';

</style>

<style lang="scss" scoped>
.home-container {
  width: 100%;
  height: 100%;
  background: var(--background-color-style);
  color: #B3B8C8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PingFangSC-Medium;
  transition: background .5s,color .5s;
  :not([class|="el"]) {
    transition: background-color .5s,color .5s;
  }
  .header {
    width: 100%;
    position: absolute;
    top: 0;
    padding: 22px 24px;
    display: flex;
    align-items: center;
    .header-item {
      &:not(:first-child) {
        margin-left: 16px;
      }
      .language{
        cursor: pointer;
      }
      .theme{
        cursor: pointer;
      }
    }
  }
}
</style>
