import axios from 'axios';
import router from '@/router';

export const baseUrl = 'https://kit.hitpub.cn';
class HttpRequest {
	constructor(baseUrl) {
		this.baseUrl = baseUrl;
	}

	interceptors(instance) {
		// 请求拦截
		instance.interceptors.request.use(config => {
			return config;
		}, error => {
			return Promise.reject(error);
		});
		// 响应拦截
		instance.interceptors.response.use(res => {
			const { data, status } = res;
			if (data.code === -14) {
                sessionStorage.removeItem('tuiRoom-roomInfo');
                router.push({ name: 'login' });
			} else {
				return { data, status };
			}
		}, error => {
			return Promise.reject(error);
		});
	}

	request(options) {
		const instance = axios.create();
		const apiAuth = sessionStorage.getItem('tuiRoom-loginToken');
		if (apiAuth === false) {
			options = Object.assign({
				baseURL: this.baseUrl,
				headers: {
					accept: 'application/json',
				},
			}, options);
		} else {
			options = Object.assign({
				baseURL: this.baseUrl,
				headers: {
					accept: 'application/json',
					'Api-Auth': apiAuth,
				},
			}, options);
		}
		this.interceptors(instance);
		return instance(options);
	}
}

export default new HttpRequest(baseUrl);
